<template lang="pug">
div(class="ml-4 mt-4 mr-4")
  v-stepper(v-model="steppers" dense alt-labels)

    v-stepper-items
      v-stepper-content(step="1" class="pa-0 pt-1")
       
        v-card(style="height: calc(100vh - 150px); overflow-y: auto")

          v-card-text(class="pb-0 pt-4")

            v-form(ref="form")
              v-row

                v-col(cols="12" md="12" class="pt-0 pb-0")
                  b Título
                  v-text-field(
                    placeholder="Informe o título da mensagem"
                    v-model="mensagem.titulo"
                    :disabled="visualizeMode"
                    :rules="[rules.required, rules.min]"
                    outlined
                    dense
                  )

                v-col(cols="12" md="3" class="pb-1 pt-0")
                  b Softhouse
                  v-radio-group(
                    v-model="mensagem.marca"
                    class="mt-0"
                    dense
                    :disabled="visualizeMode || disableSofthouse"
                    hide-details
                  )
                    v-radio(
                      label="ControlSoft"
                      value="CONTROLSOFT"
                    )
                    v-radio(
                      label="SySafra"
                      value="SYSAFRA"
                    )

                v-col(cols="12" md="3" class="pb-1 pt-0")
                  b Tipo da mensagem
                  v-radio-group(
                    v-model="mensagem.tipo"
                    class="mt-0"	
                    dense
                    :disabled="visualizeMode"
                    hide-details
                  )
                    v-radio(
                      label="Geral"
                      value="GERAL"
                    )
                    v-radio(
                      label="Administrativa"
                      value="ADMINISTRATIVA"
                    )
                    v-radio(
                      label="Sistema"
                      value="SISTEMA"
                    )

                v-col(cols="12" md="2" class="pb-1 pt-0")
                  b Nível de usuário
                  v-radio-group(
                    v-model="mensagem.nivelusuario"
                    class="mt-0"
                    dense
                    :disabled="visualizeMode"
                    hide-details
                  )
                    v-radio(
                      label="Todos"
                      value="TODOS"
                    )
                    v-radio(
                      label="Supervisores"
                      value="SUPERVISOR"
                    )

                v-col(cols="12" md="4" class="pb-1 pt-0")
                  v-checkbox(
                    v-model="mensagem.exigeconfirmacao"
                    label="Com confirmação?"
                    hide-details
                    :disabled="visualizeMode"
                    dense
                    class="mt-0 pt-0"
                  )
                  v-radio-group(
                    v-model="mensagem.tipoconfirmacao"
                    class="mt-0 ml-7"
                    dense
                    :disabled="!mensagem.exigeconfirmacao || visualizeMode"
                    hide-details
                  )
                    v-radio(
                      label="Ao menos uma confirmação de usuário"
                      value="UM"
                    )
                    v-radio(
                      label="Confirmação de todos os usuários"
                      value="TODOS"
                    )
                  v-checkbox(
                    v-model="mensagem.bloqueiasistema"
                    label="Bloqueia sistema?"
                    hide-details
                    :disabled="!mensagem.exigeconfirmacao || visualizeMode"
                    dense
                    class="mt-0 pt-0 mt-2"
                  )

                v-col(cols="12" md="12")
                  vue-editor(
                    id="mensagemEditor" 
                    v-model="mensagem.mensagem" 
                    :disabled="visualizeMode"
                    :readOnly="false"
                    :editorToolbar="customToolbar"
                    :rules="[rules.required, rules.min50]"
                    :placeholder="!visualizeMode ? 'Digite a mensagem...' : ''"
                  )

      v-stepper-content(step="2" class="pa-0")
        v-card(style="height: calc(100vh - 146px); overflow-y: auto")
          v-card-text(class="pa-0 pt-2")

            v-row(class="ma-0 ml-2")
              v-col(cols="12" md="12" class="mb-2 mt-0")
                v-radio-group(
                  v-model="destinatariosFiltros.envioPorSelecao"
                  label="Tipo de Envio:"
                  :disabled="visualizeMode || !loaded"
                  class="mt-0"
                  dense
                  hide-details
                  row
                )
                  v-radio(
                    label="Todos os destinatários"
                    :value="false"
                  )
                  v-radio(
                    label="Filtrar destinatários"
                    :value="true"
                  )
           
              v-col(cols="12" md="3" class="pt-1")
                span(
                  :style="destinatariosFiltros.envioPorSelecao && !visualizeMode ? 'color: #000' : 'color: #ccc'"
                ) Cliente
                v-autocomplete(
                  placeholder="< T O D O S >"
                  persistent-placedholder
                  v-model="destinatariosFiltros.listacliente"
                  :items="filteredDatasetEndPoint"
                  :disabled="!destinatariosFiltros.envioPorSelecao || visualizeMode"
                  return-object
                  :item-text="item => item.nome"
                  item-value="idcliente"
                  dense
                  outlined
                  hide-details
                  multiple
                  @change="filtraDestinatariosEmpresa"
                  :loading="!loaded"
                )
                  template(v-slot:prepend-item)
                    v-list-item(@click="clearBase")
                      v-list-item-content
                        v-list-item-title Todos (Sem seleção)

                  template(v-slot:selection="{ item, index }")
                    span(v-if="index === 0") {{ destinatariosFiltros.listacliente.length }} Base(s)

              v-col(cols="12" md="3" class="pt-1")
                span(
                  :style="destinatariosFiltros.envioPorSelecao && !visualizeMode ? 'color: #000' : 'color: #ccc'"
                ) Caracterização
                v-autocomplete(
                  placeholder="< T O D O S >"
                  persistent-placedholder
                  v-model="destinatariosFiltros.listatipoempresa"
                  :items="datasetTipoEmpresa"
                  :disabled="!destinatariosFiltros.envioPorSelecao || visualizeMode"
                  return-object
                  item-text="value"
                  item-value="id"
                  dense
                  outlined
                  hide-details
                  multiple
                  @change="filtraDestinatariosEmpresa"
                  :loading="!loaded"
                )

                  template(v-slot:prepend-item)
                    v-list-item(@click="clearCaracterizacao")
                      v-list-item-content
                        v-list-item-title Todos (Sem seleção)

                  template(v-slot:selection="{ item, index }")
                    span(v-if="index === 0") {{ destinatariosFiltros.listatipoempresa.length }} Caracterização(ões)

              v-col(cols="12" md="3" class="pt-1")
                span(
                  :style="destinatariosFiltros.envioPorSelecao && !visualizeMode ? 'color: #000' : 'color: #ccc'"
                ) Módulos
                v-autocomplete(
                  placeholder="< T O D O S >"
                  persistent-placedholder
                  v-model="destinatariosFiltros.listamodulo"
                  :items="datasetModulos"
                  :disabled="!destinatariosFiltros.envioPorSelecao || visualizeMode"
                  return-object
                  item-text="value"
                  item-value="id"
                  outlined
                  dense
                  hide-details
                  multiple
                  @change="filtraDestinatariosEmpresa"
                  :loading="!loaded"
                )
                  template(v-slot:prepend-item)
                    v-list-item(@click="clearModulo")
                      v-list-item-content
                        v-list-item-title Todos (Sem seleção)

                  template(v-slot:selection="{ item, index }")
                    span(v-if="index === 0") {{ destinatariosFiltros.listamodulo.length }} Módulo(s)

              v-col(cols="12" md="3" class="pt-1")
                span(
                  :style="destinatariosFiltros.envioPorSelecao && !visualizeMode ? 'color: #000' : 'color: #ccc'"
                ) Estados
                v-autocomplete(
                  placeholder="< T O D O S >"
                  persistent-placedholder
                  v-model="destinatariosFiltros.listauf"
                  :items="datasetEstados"
                  :disabled="!destinatariosFiltros.envioPorSelecao || visualizeMode"
                  return-object
                  item-text="value"
                  item-value="id"
                  outlined
                  dense
                  hide-details
                  multiple
                  :loading="!loaded"
                  @change="filtraDestinatariosEmpresa"
                )
                  template(v-slot:prepend-item)
                    v-list-item(@click="clearEstado")
                      v-list-item-content
                        v-list-item-title Todos (Sem seleção)

                  template(v-slot:selection="{ item, index }")
                    span(v-if="index === 0") {{ destinatariosFiltros.listauf.length }} Estado(s)

              v-col(cols="12" md="12" class="mt-0 mb-0 pb-0 pt-0")
                v-radio-group(
                  label="Empresas: "
                  v-model="destinatariosFiltros.envioPorEmpresa"
                  :disabled="!destinatariosFiltros.envioPorSelecao || visualizeMode"
                  class="mt-0"
                  dense
                  hide-details
                  row
                )
                  v-radio(
                    label="Todas"
                    value="TODAS"
                  )
                  v-radio(
                    label="Matrizes"
                    value="MATRIZ"
                  )

                  v-radio(
                    label="Seleção"
                    value="SELECAO"
                  )

              v-col(cols="12" md="12" class="mt-0 mb-0 pb-0 pt-0 pr-0")
                v-divider(class="ma-0 pa-0 mt-4")
                div(class="pt-0" style="height: calc(100vh - 340px); overflow-y: auto")
                  v-data-table(
                    v-model="destinatariosFiltros.listaempresa"
                    outlined
                    dense
                    hide-details
                    item-key="idempresa"
                    :single-select="false"
                    :disabled="!destinatariosFiltros.envioPorEmpresa == 'SELECAO' || visualizeMode"
                    :show-select="destinatariosFiltros.envioPorEmpresa == 'SELECAO' || !visualizeMode" 
                    :headers="destinatariosFiltros.envioPorEmpresa == 'SELECAO' ? destinatariosHeaders : []"
                    :items="destinatariosFiltros.envioPorEmpresa == 'SELECAO' ? datasetEmpresasFiltered : []"
                    :footer-props="propsFooter"
                    class="AgroNotificacaoClientesTable"
                    :loading="!loaded"
                    loading-text="Carregando clientes e empresas..."
                  )
                    template(v-slot:item.base="{ on, item }")
                      span {{ item.base }}


                    template(v-slot:header.data-table-select="{ on, props }")
                      v-simple-checkbox(
                        color="primary"
                        class="ma-0 pa-0"
                        :disabled="visualizeMode"
                        v-bind="props"
                        v-on="on"
                        :ripple="false"
                        hide-details
                      )

                    template(v-slot:item.data-table-select="{ item, isSelected, select }")
                      v-simple-checkbox(
                        color="primary"
                        class="ma-0 pa-0"
                        :value="isSelected"
                        @input="select"
                        :disabled="visualizeMode"
                        :ripple="false"
                        hide-details
                      )
                    
                    template(v-slot:no-data)
                 
                      center
                        v-card(v-if="destinatariosFiltros.envioPorEmpresa == 'SELECAO' && datasetEmpresasFiltered.length == 0"
                          :disabled="!destinatariosFiltros.envioPorSelecao"
                          width="300px"
                          class="pa-8 mt-8 mb-2 align-center justify-center"
                          outlined
                          elevation="0"
                        )

                          span(
                            :style="destinatariosFiltros.envioPorSelecao && !visualizeMode ? 'color: #000' : 'color: #333'"
                          ) Nenhum registros encontrado para os filtros aplicados

                        v-card(v-if="destinatariosFiltros.envioPorEmpresa != 'SELECAO'"
                          :disabled="!destinatariosFiltros.envioPorSelecao"
                          width="300px"
                          class="pa-8 mt-8 mb-2 align-center justify-center"
                          outlined
                          elevation="0"
                        )
                          span(
                            :style="destinatariosFiltros.envioPorSelecao && !visualizeMode ? 'color: #000' : 'color: #333'"
                          ) As empresas serão exibidas quando a opção de seleção estiver ativa no seletor acima

    div(class="d-flex justify-end mb-3 mr-3 mt-0")
      v-spacer

      // Botão de voltar é inalteravel
      v-btn(
        color="primary"
        @click="steppers == 1 ? backPage() : steppers--"
        outlined
      ) 
        v-icon(left) mdi mdi-arrow-left
        | Voltar

      // Caso esteja na etapa 1, exibe o botão de próximo
      v-btn(
        v-if="steppers == 1"	
        class="ml-2"
        color="primary"
        @click="validaStepperUm"
        outlined
      ) Próximo
        v-icon(right) mdi mdi-arrow-right

      // Caso esteja na etapa 2, exibe o botão de salvar
      v-btn(
        v-else-if="steppers == 2 && !visualizeMode"
        class="ml-2"
        color="success"
        :disabled="visualizeMode || salvarIsClicked"
        outlined
        @click="sendData()"
      ) 
        v-icon(left) mdi mdi-check
        | Salvar

      // Caso esteja na etapa 2, exibe o botão de voltar
      v-btn(
        v-else-if="steppers == 2 && visualizeMode"
        class="ml-2"
        color="primary"
        outlined
        to="/agronotificacao"
      ) 
        v-icon(left) mdi mdi-close
        | Fechar

</template>

<script>
import csapi from "@/api/csapi";
import { mapMutations } from "vuex";
import { state } from "@/socket";
import { VueEditor } from "vue2-editor";
import { clientes as api } from "@/api";

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      loaded: false,
      steppers: 1,
      disableSofthouse: false,
      datasetEndPoint: [],
      visualizeMode: false,
      salvarIsClicked: false,
      mensagem: {
        titulo: "",
        marca: "CONTROLSOFT",
        tipo: "GERAL",
        nivelusuario: "TODOS",
        exigeconfirmacao: false,
        bloqueiasistema: false,
        tipoconfirmacao: "UM",
        mensagem: ""
      },
      destinatariosHeaders: [
        { text: "Cliente", value: "base", sortable: false },
        { text: "Cód. Empresa", value: "codigodb", width: "150", sortable: false },
        { text: "Razão Social Empresa", value: "razao", sortable: false },
        { text: "Caracterização", value: "tipoempresa", sortable: false },
        { text: "Estado", value: "sigla", align: "center", sortable: false }
      ],
      destinatariosFiltros: {
        envioPorSelecao: false,
        envioPorEmpresa: "TODAS",
        listacliente: [],
        listatipoempresa: [],
        listauf: [],
        listamodulo: [],
        listaempresa: []
      },
      datasetEmpresas: [],
      datasetEmpresasFiltered: [],
      customToolbar: [["bold", "italic", "underline", "strike"], ["link"]],
      customToolbarVisualize: [[]],
      datasetEstados: [
        { id: "AC", value: "Acre" },
        { id: "AL", value: "Alagoas" },
        { id: "AP", value: "Amapá" },
        { id: "AM", value: "Amazonas" },
        { id: "BA", value: "Bahia" },
        { id: "CE", value: "Ceará" },
        { id: "DF", value: "Distrito Federal" },
        { id: "ES", value: "Espírito Santo" },
        { id: "GO", value: "Goiás" },
        { id: "MA", value: "Maranhão" },
        { id: "MT", value: "Mato Grosso" },
        { id: "MS", value: "Mato Grosso do Sul" },
        { id: "MG", value: "Minas Gerais" },
        { id: "PA", value: "Pará" },
        { id: "PB", value: "Paraíba" },
        { id: "PR", value: "Paraná" },
        { id: "PE", value: "Pernambuco" },
        { id: "PI", value: "Piauí" },
        { id: "RJ", value: "Rio de Janeiro" },
        { id: "RN", value: "Rio Grande do Norte" },
        { id: "RS", value: "Rio Grande do Sul" },
        { id: "RO", value: "Rondônia" },
        { id: "RR", value: "Roraima" },
        { id: "SC", value: "Santa Catarina" },
        { id: "SP", value: "São Paulo" },
        { id: "SE", value: "Sergipe" },
        { id: "TO", value: "Tocantins" }
      ],
      datasetModulos: [
        { id: 16, value: "Comercialização", field: "utilizacscomercializacao" },
        { id: 4, value: "Contabilidade", field: "utilizacscontabilidade" },
        { id: 11, value: "Emissor", field: "utilizacsemissor" },
        { id: 5, value: "Escrita Fiscal", field: "utilizacsescritafiscal" },
        { id: 25, value: "Faturamento", field: "utilizacsfaturamento" },
        { id: 24, value: "Financeiro", field: "utilizacsfinanceiro" },
        { id: 17, value: "Lalur", field: "utilizacslalur" },
        { id: 22, value: "LCDPR", field: "utilizacslcdpr" },
        { id: 10, value: "Romaneio", field: "utilizacsromaneio" },
        { id: 14, value: "Tributação", field: "utilizacstributacao" },
        { id: 8, value: "CS Web", field: "utilizamodulocsweb" },
        { id: 13, value: "Lib. Supervisor", field: "utilizamodulolibsupervisor" },
        { id: 26, value: "Pesador", field: "utilizamodulopesador" }
      ],
      datasetTipoEmpresa: [
        { id: 1, value: "Armazém Geral" },
        { id: 2, value: "Revenda e Representação Comercial" },
        { id: 3, value: "Cooperativa" },
        { id: 4, value: "Condomínio Rural" },
        { id: 5, value: "Produtor Rural" },
        { id: 6, value: "Cerealista e Comercialização" },
        { id: 7, value: "Agroindústria" },
        { id: 8, value: "Peças e Agropecuária" },
        { id: 9, value: "Unidade de Recebimento de Embalagens" },
        { id: 99, value: "Financeiro" }
      ],
      rules: {
        required: value => !!value || "Campo obrigatório.",
        min: v => v.length >= 10 || "Mínimo de 10 caracteres",
        min50: v => v.length >= 50 || "Mínimo de 50 caracteres"
      },
      propsFooter: {
        itemsPerPageOptions: [30, 50, 100, 200, 300, 500, -1],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: "Itens por página"
      }
    };
  },

  computed: {
    filteredDatasetEndPoint() {
      return this.datasetEndPoint.filter(
        item => item.softhouse.toUpperCase() === this.mensagem.marca.toUpperCase()
      );
    }
  },

  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    backPage() {
      this.$router.push(`/agronotificacao`);
    },

    async getData() {
      const id = Number(this.$route.params.id);

      try {
        if (Number.isInteger(id) && id > 0) {
          this.showLoader("Carregando Notificação...");
          const API_URL = `/v1/agronotificacao/${id}`;
          const res = await csapi.get(API_URL);

          //Redirecionar para visualizacao caso status seja enviado
          if (res.data.status.toUpperCase() == "ENVIADO" && this.visualizeMode == false) {
            this.visualizeMode = true;
            this.$router.push(`/agronotificacao/visualizacao/${id}`);
          }

          this.mensagem = res.data;
          this.hideLoader();
        }
      } catch (error) {
        console.error(error);
        this.$toast.error(`Erro ao carregar notificação: ${error.message}`);
      }

      try {
        api.refreshAuthProd(this.$store.state.auth);
        const data = await api.getAll();
        this.datasetEndPoint = data
          .filter(item => item.statusbase === 1)
          .sort((a, b) => {
            if (a.nome != null && b.nome != null) return a.nome.localeCompare(b.nome);
          });

        // Tratamento de dados para deixar mais facil a manipulação
        this.datasetEndPoint.forEach(cliente => {
          cliente.empresas.forEach(empresa => {
            empresa.id = cliente.idcliente;
            empresa.base = cliente.nome;
            empresa.hash = cliente.hash;

            if (empresa.modulos) {
              let keysModulo = Object.keys(empresa.modulos);
              let listaModulos = [];
              keysModulo.forEach(key => {
                if (empresa.modulos[key] == "S") {
                  let idModulo = this.datasetModulos.find(item => item.field == key);
                  if (idModulo) listaModulos.push(idModulo.id);
                }
              });
              empresa.modulos = listaModulos;
            } else {
              empresa.modulo = [];
            }
          });
        });

        this.datasetEmpresasFiltered = this.datasetEmpresas = this.filteredDatasetEndPoint
          .map(item => item.empresas)
          .flat();

        this.loaded = true;

        if (Number.isInteger(id) && id > 0) {
          this.recuperarDestinatariosFiltros();
          this.filtraDestinatariosEmpresa();
        }
      } catch (e) {
        this.$toast.error("Erro ao carregar dados para uso do cadastro");
      }
    },

    filtraDestinatariosEmpresa() {
      let copiaDataSetEmpresas = [...this.datasetEmpresas];

      if (
        this.destinatariosFiltros.listacliente.length == 0 &&
        this.destinatariosFiltros.listatipoempresa.length == 0 &&
        this.destinatariosFiltros.listauf.length == 0 &&
        this.destinatariosFiltros.listamodulo.length == 0
      ) {
        this.datasetEmpresasFiltered = copiaDataSetEmpresas;
        return false;
      }

      if (this.destinatariosFiltros.listacliente.length > 0) {
        copiaDataSetEmpresas = copiaDataSetEmpresas.filter(item => {
          return this.destinatariosFiltros.listacliente
            .map(item2 => item2.hash)
            .includes(item.hash);
        });
      }

      if (this.destinatariosFiltros.listatipoempresa.length > 0) {
        copiaDataSetEmpresas = copiaDataSetEmpresas.filter(item => {
          return this.destinatariosFiltros.listatipoempresa
            .map(item2 => item2.id + " - " + item2.value)
            .includes(item.tipoempresa);
        });
      }

      if (this.destinatariosFiltros.listamodulo.length > 0) {
        copiaDataSetEmpresas = copiaDataSetEmpresas.filter(item => {
          return this.destinatariosFiltros.listamodulo
            .map(item2 => item2.id)
            .some(item3 => (item.modulos !== null ? item.modulos.includes(item3) : []));
        });
      }

      if (this.destinatariosFiltros.listauf.length > 0) {
        copiaDataSetEmpresas = copiaDataSetEmpresas.filter(item => {
          return this.destinatariosFiltros.listauf.map(item2 => item2.id).includes(item.sigla);
        });
      }

      this.datasetEmpresasFiltered = copiaDataSetEmpresas;
    },

    recuperarDestinatariosFiltros() {
      if (
        this.mensagem.listaclienteempresa.length == 0 &&
        this.mensagem.listatipoempresa.length == 0 &&
        this.mensagem.listauf.length == 0 &&
        this.mensagem.listamodulo.length == 0
      ) {
        this.destinatariosFiltros.envioPorSelecao = false;
        return false;
      }

      this.destinatariosFiltros.envioPorSelecao = true;

      if (this.mensagem.listatipoempresa.length > 0) {
        this.destinatariosFiltros.listatipoempresa = this.datasetTipoEmpresa.filter(item =>
          this.mensagem.listatipoempresa.includes(item.id)
        );
      }

      if (this.mensagem.listauf.length > 0) {
        this.destinatariosFiltros.listauf = this.datasetEstados.filter(item =>
          this.mensagem.listauf.includes(item.id)
        );
      }

      if (this.mensagem.listamodulo.length > 0) {
        this.destinatariosFiltros.listamodulo = this.datasetModulos.filter(item =>
          this.mensagem.listamodulo.includes(item.id)
        );
      }

      if (Array.isArray(this.mensagem.listaclienteempresa)) {
        this.destinatariosFiltros.envioPorEmpresa = "SELECAO";
        this.destinatariosFiltros.listacliente = this.filteredDatasetEndPoint.filter(item =>
          this.mensagem.listaclienteempresa.map(item => item.base).includes(item.hash)
        );

        // Remontar estrutura de listaempresa
        let listaHashCodigoDb = [];
        this.mensagem.listaclienteempresa.forEach(t => {
          if (t.empresas.length > 0) {
            t.empresas.forEach(x => {
              listaHashCodigoDb.push({
                hash: t.base,
                codigodb: x
              });
            });
          }
        });

        this.destinatariosFiltros.listaempresa = this.datasetEmpresasFiltered.filter(
          item =>
            listaHashCodigoDb.map(item => item.hash).includes(item.hash) &&
            listaHashCodigoDb.map(item => item.codigodb).includes(item.codigodb)
        );
      } else {
        if (
          this.mensagem.listaclienteempresa.empresas == "MATRIZ" &&
          this.mensagem.listaclienteempresa.base.length == 0
        ) {
          this.destinatariosFiltros.envioPorEmpresa = "MATRIZ";
        } else if (
          this.mensagem.listaclienteempresa.empresas == "MATRIZ" &&
          this.mensagem.listaclienteempresa.base.length > 0
        ) {
          this.destinatariosFiltros.envioPorEmpresa = "MATRIZ";
          this.destinatariosFiltros.listacliente = this.filteredDatasetEndPoint.filter(item =>
            this.mensagem.listaclienteempresa.base.includes(item.hash)
          );
        } else if (
          this.mensagem.listaclienteempresa.empresas.length == 0 &&
          this.mensagem.listaclienteempresa.base.length > 0
        ) {
          this.destinatariosFiltros.envioPorEmpresa = "TODAS";
          this.destinatariosFiltros.listacliente = this.filteredDatasetEndPoint.filter(item =>
            this.mensagem.listaclienteempresa.base.includes(item.hash)
          );
        } else {
          this.destinatariosFiltros.envioPorSelecao = false;
          this.destinatariosFiltros.envioPorEmpresa = "TODAS";
        }
      }
    },

    validaStepperUm() {
      if (!this.$refs.form.validate()) {
        this.$toast.error("Preencha os campos obrigatórios");
        return false;
      }

      const text = document.getElementById("mensagemEditor").innerText;
      if (text.length < 30) {
        this.$toast.error("A mensagem deve conter no mínimo 30 caracteres");
        return false;
      }

      this.steppers++;
    },

    sendValidade() {
      if (this.destinatariosFiltros.envioPorSelecao) {
        if (
          this.destinatariosFiltros.listacliente.length == 0 &&
          this.destinatariosFiltros.listatipoempresa.length == 0 &&
          this.destinatariosFiltros.listauf.length == 0 &&
          this.destinatariosFiltros.listamodulo.length == 0
        ) {
          this.$toast.error("Selecione ao menos um filtro para os destinatários");
          return false;
        }
      }

      if (
        this.destinatariosFiltros.envioPorSelecao &&
        this.destinatariosFiltros.envioPorEmpresa == "SELECAO"
      ) {
        if (this.destinatariosFiltros.listaempresa.length == 0) {
          this.$toast.error(
            "Opcão de seleção de empresas marcada, porém nenhuma empresa foi selecionada"
          );
          return false;
        }
      }

      return true;
    },

    async sendData() {
      if (!this.$refs.form.validate()) {
        this.$toast.error("Preencha os campos obrigatórios");
        return false;
      }

      if (!this.sendValidade()) {
        return false;
      }

      this.salvarIsClicked = true;
      // this.showLoader('Salvando Notificação...');

      let listaclienteempresa = [];

      // 1 - Caso selecionado todos os destinatários
      if (this.destinatariosFiltros.envioPorSelecao == false) {
        listaclienteempresa = [];
      }
      // 2 - Caso selecionado bases, e marcado como todas as empresas das bases
      else if (
        this.destinatariosFiltros.listacliente.length > 0 &&
        this.destinatariosFiltros.envioPorEmpresa == "TODAS"
      ) {
        listaclienteempresa = {
          base: this.destinatariosFiltros.listacliente.map(item => item.hash),
          empresas: []
        };
      }
      // 3 - Caso não selecione bases, mas marca como matrizes
      else if (
        this.destinatariosFiltros.listacliente.length > 0 &&
        this.destinatariosFiltros.envioPorEmpresa == "MATRIZ"
      ) {
        listaclienteempresa = {
          base: this.destinatariosFiltros.listacliente.map(item => item.hash),
          empresas: "MATRIZ"
        };
      }
      // 4 - Caso não selecione bases, mas marca como matrizes
      else if (
        this.destinatariosFiltros.listacliente.length == 0 &&
        this.destinatariosFiltros.envioPorEmpresa == "MATRIZ"
      ) {
        listaclienteempresa = {
          base: [],
          empresas: "MATRIZ"
        };
      }
      // 5 - Caso selecionado bases, e marcado como seleção de empresas
      else if (this.destinatariosFiltros.envioPorEmpresa == "SELECAO") {
        let listaHashFiltroCliente = this.destinatariosFiltros.listacliente.map(item => item.hash);
        let listaHashFiltroEmpresa = [
          ...new Set(this.destinatariosFiltros.listaempresa.map(item => item.hash))
        ];

        for (let i = 0; i < listaHashFiltroEmpresa.length; i++) {
          // caso selecionado em empresas e não estiver selecionado no filtro
          if (listaHashFiltroCliente.findIndex(item => item == listaHashFiltroEmpresa[i]) == -1) {
            listaclienteempresa.push({
              base: listaHashFiltroEmpresa[i],
              empresas: this.destinatariosFiltros.listaempresa
                .filter(item => item.hash == listaHashFiltroEmpresa[i])
                .map(item => item.codigodb)
            });
            delete listaHashFiltroEmpresa[i];
          } else {
            listaclienteempresa.push({
              base: listaHashFiltroEmpresa[i],
              empresas: this.destinatariosFiltros.listaempresa
                .filter(item => item.hash == listaHashFiltroEmpresa[i])
                .map(item => item.codigodb)
            });
            delete listaHashFiltroCliente[
              listaHashFiltroCliente.findIndex(item => item == listaHashFiltroEmpresa[i])
            ];
            delete listaHashFiltroEmpresa[i];
          }
        }

        let listaHashFiltroClienteSemUndefined = listaHashFiltroCliente.filter(
          item => item != undefined
        );

        for (let i = 0; i < listaHashFiltroClienteSemUndefined.length; i++) {
          listaclienteempresa.push({
            base: listaHashFiltroClienteSemUndefined[i],
            empresas: []
          });
        }
      }

      const envio = {
        titulo: this.mensagem.titulo,
        marca: this.mensagem.marca,
        tipo: this.mensagem.tipo,
        nivelusuario: this.mensagem.nivelusuario,
        exigeconfirmacao: this.mensagem.exigeconfirmacao,
        bloqueiasistema: this.mensagem.bloqueiasistema,
        tipoconfirmacao: this.mensagem.tipoconfirmacao,
        mensagem: this.mensagem.mensagem,
        listatipoempresa: this.destinatariosFiltros.listatipoempresa.map(item => item.id),
        listauf: this.destinatariosFiltros.listauf.map(item => item.id),
        listamodulo: this.destinatariosFiltros.listamodulo.map(item => item.id),
        listaclienteempresa: listaclienteempresa
      };

      try {
        if (this.$route.params.id == 0) {
          const { login, name } = JSON.parse(JSON.stringify(this.$store.state.auth.user));
          envio.usuariocadastro = login + ":" + name;
          await csapi.post("/v1/agronotificacao", envio);
        } else {
          envio.usuariocadastro = this.mensagem.usuariocadastro;
          await csapi.put(`/v1/agronotificacao/${this.$route.params.id}`, envio);
        }

        this.hideLoader();
        this.$toast.success("Notificação salva com sucesso!");
        this.$router.push("/agronotificacao");
      } catch (error) {
        console.error(error.response);
        this.$toast.error(`Erro ao cadastrar Notificação: ${error.message}`);
      } finally {
        this.salvarIsClicked = false;
        this.hideLoader();
      }
    },

    clearBase() {
      this.destinatariosFiltros.listacliente = [];
      this.filtraDestinatariosEmpresa();
    },

    clearCaracterizacao() {
      this.destinatariosFiltros.listatipoempresa = [];
      this.filtraDestinatariosEmpresa();
    },

    clearModulo() {
      this.destinatariosFiltros.listamodulo = [];
      this.filtraDestinatariosEmpresa();
    },

    clearEstado() {
      this.destinatariosFiltros.listauf = [];
      this.filtraDestinatariosEmpresa();
    }
  },
  watch: {
    "destinatariosFiltros.envioPorSelecao": function(val) {
      if (val == false) {
        this.destinatariosFiltros = {
          envioPorSelecao: false,
          envioPorEmpresa: "TODAS",
          listacliente: [],
          listatipoempresa: [],
          listauf: [],
          listamodulo: [],
          listaempresa: []
        };
      }
    },

    "destinatariosFiltros.envioPorEmpresa": function(val) {
      if (val != "SELECAO") {
        this.destinatariosFiltros.listaempresa = [];
      }
    },

    "mensagem.exigeconfirmacao"() {
      if (!this.mensagem.exigeconfirmacao) {
        this.mensagem.bloqueiasistema = false;
      }
    },

    steppers(newVal) {
      if (newVal == 2) {
        this.disableSofthouse = true;
      }
    }
  },

  created() {
    if (this.$route.path.includes("visualizacao")) {
      this.visualizeMode = true;
      this.customToolbar = this.customToolbarVisualize;
    }
  },

  mounted() {
    this.isControl(true);
    this.setTitle(
      Number(this.$route.params.id) > 0 ? "Notificação - Alteração" : "Notificação - Inclusão"
    );
    if (this.visualizeMode) this.setTitle("Notificação - Visualização");

    this.getData();
  }
};
</script>

<style>
.AgroNotificacaoClientesTable .v-data-table__wrapper {
  height: calc(100vh - 400px);
  overflow-y: auto;
}
</style>
